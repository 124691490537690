
export const isEmpty = (data) => {
	if (data === null) {
		return true;
	}
	if (typeof data === 'undefined') {
		return true;
	}
	if (typeof data.length === 'number' && data.length === 0) {
		return true;
	}
	if (typeof data === 'number' && data === 0) {
		return true;
	}
	return false;
};

export const twoChars = (val) => {
	return ('0' + val).slice(-2);
};

export const formatDate = (value, format = 'YYYY-MM-DD') => {
	const date = new Date(value);

	switch (format) {
		case 'MM/DD':
			return `${twoChars(date.getMonth() + 1)}/${twoChars(date.getDate())}`;
		case 'DD/MM':
			return `${twoChars(date.getDate())}/${twoChars(date.getMonth() + 1)}`;
		case 'DD/MM/YY':
			return `${twoChars(date.getDate())}/${twoChars(date.getMonth() + 1)}/${date.getFullYear()}`;
		case 'YYYY-MM-DD':
			return `${date.getFullYear()}-${twoChars(date.getMonth() + 1)}-${twoChars(date.getDate())}`;
	}
	return value;
};

export const rankingTypeName = (code) => {
	switch (code) {
		case 'ITE':
			return 'Étape';
		case 'ITG':
			return 'Général';
	}
	return code;
};

export const eventColorFg = (code) => {
	switch (code) {
		case 'IBE':
			return 'black';
	}
	return 'white';
};
export const eventColorBg = (code) => {
	switch (code) {
		case 'START':
			return '#000f77';
		case 'IME': // mountain
			return '#ff0008';
		case 'IPE': // points
		case 'IPA': // points
			return '#0c7c00';
		case 'CLM': // Contre la montre
			return 'white';
		case 'IBE': // bonus
		case 'INTER': // Général Points Autres Sprints
			return '#ffff00'; // yellow
		case 'IOE':
			return '#000f77'; // blue
	}
	return 'black';
};

export const eventIcon = (category, code) => {
	if (isEmpty(category) === false) {
		return `/images/poi/${category}.svg`;
	}
	if (['END'].includes(code) === true) {
		return `/images/poi/arrival.svg`;
	}
	if (['START'].includes(code) === true) {
		return `/images/poi/start.svg`;
	}
	return `/images/poi/commissaire.svg`;
};

export const formatComma = (value) => String(value).replace('.', ',').replace(',00', '');

export const isValidDate = (d) => (d instanceof Date && isNaN(d) === false);

export const dateTimeDiff = (date) => {
	let seconds = (new Date().getTime() - date.getTime()) / 1000;

	const min = seconds / 60;
	if (min > 0) {
		seconds = seconds - (Math.floor(min) * 60);
	}
	if (min >= 60) {
		return `+1h`;
	}
	if (seconds < 0) {
		seconds = 0;
	}
	return `${Math.floor(min) > 0 ? Math.floor(min) + ' min' : ''} ${Math.round(seconds)} sec`.trim();
};
