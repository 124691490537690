import API from '../modules/API';
import {isEmpty, twoChars, isValidDate, dateTimeDiff} from '../modules/tools';
import Config from '../modules/config';

let timeoutId = null;
let pageVisibile = true;

document.addEventListener('visibilitychange', () => {
	pageVisibile = (document.visibilityState === 'visible');
});

export default () => {
	return {
		template: require('/src/pages/competition.html'),
		data() {
			return {
				// competition
				competition: null,
				competitionError: null,
				competitionLoading: false,
				// stage
				currentStage: null,
				stageError: null,
				stageLoading: false,
				// live positions
				liveTimePosition: {},
				// search
				search: '',
			};
		},
		watch: {
			'$route.params.id'() {
				this.getCompetition();
			},
			'$route.params.stageId'() {
				this.selectStage();
			},
			'$live.count'() {
				this.setTrackerLiveTimePosition();
			},
		},
		created() {
			this.getCompetition();
		},
		unmounted() {
			this.stopGetTrackers();
		},
		computed: {
			stageEvents() {
				let globalEvents = [
					{
						code: 'START',
						num: 0,
						event: "0",
						name: 'Départ',
						km: 0,
						type: 'S',
					},
				];
				if (this.currentStage !== null) {
					if (isEmpty(this.currentStage.events) === false) {
						globalEvents = [...globalEvents, ...this.currentStage.events];
					}
					if (isEmpty(this.currentStage.intermediates) === false) {
						globalEvents = [...globalEvents, ...this.currentStage.intermediates];
					}
					globalEvents.push({
						code: 'END',
						num: 0,
						event: "0",
						name: 'Arrivée',
						km: this.currentStage.length,
						type: 'A',
					});
				}
				globalEvents = globalEvents.filter((e) => e.hidden !== true);

				return globalEvents.sort((a, b) => {
					if (a.km > b.km) {
						return 1;
					}
					if (a.km < b.km) {
						return -1;
					}
					if (a.km === b.km) {
						return 0;
					}
				});
			},
			countTrackersTotal() {
				return this.competition.trackers.length;
			},
			countTrackersHasPosition() {
				return this.$live.trackerPositions.filter((t) => t.lat && t.lon).length;
			},
			filteredTrackers() {
				if (isEmpty(this.search) === true) {
					return this.competition.trackers.sort((a, b) => {
						const posA = this.$live.trackerPositions.find(t => t.id === a.id);
						const posB = this.$live.trackerPositions.find(t => t.id === b.id);

						if (isEmpty(posA) === true && isEmpty(posB) === true) {
							return 0;
						}
						if (isEmpty(posA) === false && isEmpty(posB) === true) {
							return -1;
						}
						if (isEmpty(posA) === true && isEmpty(posB) === false) {
							return 1;
						}
						return posA.time > posB.time ? 1 : -1;
					});
				}

				return this.competition.trackers.filter((t) => {
					return t.name.toLowerCase().includes(this.search.toLowerCase()) === true;
				});
			},
		},
		methods: {
			async getCompetition() {
				const competitionId = this.$route.params.id;

				this.competitionError = null;
				this.competitionLoading = false;

				try {
					this.competitionLoading = true;
					this.competition = await API.getCompetition(competitionId, {$select: ['id', 'name', 'stages', 'trackers', 'tracking']});
					if (isEmpty(this.competition) === false) {
						document.title = this.competition.name;

						this.startGetTrackers();
						this.selectStage();
					}
				} catch (exception) {
					this.competitionError = exception;
				}
				this.competitionLoading = false;
			},
			selectStage() {
				if (isEmpty(this.$route.params.stageId) === true) {
					const newDate = new Date();
					const stageDateFormmatted = `${newDate.getFullYear()}-${twoChars(newDate.getMonth() + 1)}-${twoChars(newDate.getDay())}`;

					for (let stage of this.competition.stages) {
						//date: "2023-05-16"
						if (stage.date === stageDateFormmatted) {
							return this.$router.replace(`/competitions/${this.competition.id}/${stage.id}`);
						}
					}
					this.$router.replace(`/competitions/${this.competition.id}/${this.competition.stages[0].id}`);
				} else {
					this.getStage();
				}
			},
			async startGetTrackers() {
				await this.getTrackers();

				timeoutId = window.setTimeout(async() => {
					await this.startGetTrackers();
				}, Config.refreshTracker);
			},
			stopGetTrackers() {
				//console.log('stopGetTrackers!');
				window.clearTimeout(timeoutId);
			},
			async getTrackers() {
				// no live when page is not visible
				if (pageVisibile === false) {
					return;
				}

				if (isEmpty(this.competition.trackers) === true) {
					return;
				}

				const trackerLocations = await API.getTrackers(this.competition.trackers.map(t => t.id));
				//console.log('trackerLocations:', trackerLocations);

				const tempTrackers = [];

				for (let trackerObj of this.competition.trackers) {
					const trackerLocation = trackerLocations[trackerObj.id];
					if (isEmpty(trackerLocation) === true) {
						continue;
					}
					tempTrackers.push(Object.assign(trackerLocation[0], trackerObj));
				}

				this.$live.trackerPositions = tempTrackers;
				this.$live.count++;
			},
			async getStage() {
				const stageId = this.$route.params.stageId;
				this.stageError = null;
				this.stageLoading = false;
				this.currentStage = null;

				try {
					this.stageLoading = true;
					const stage = await API.getStage(stageId);
					if (isEmpty(stage) === false) {
						document.title = stage.name;

						stage.otherStages = await this.getOtherStages();
						this.currentStage = stage;
					}
				} catch (exception) {
					this.stageError = exception;
				}
				this.stageLoading = false;
			},
			async getOtherStages() {
				const otherStagesIds = this.competition.stages.filter(s => s.id !== this.$route.params.stageId).map(s => s.id);

				const otherStages = [];

				try {
					for (let otherStagesId of otherStagesIds) {
						const otherStage = await API.getStage(otherStagesId);
						if (isEmpty(otherStage) === false) {
							otherStages.push(otherStage);
						}
					}
				} catch (exception) {
					this.stageError = exception;
				}

				return otherStages;
			},
			setTrackerLiveTimePosition() {
				this.liveTimePosition = {};

				for (let trackerPosition of this.$live.trackerPositions) {
					const newDate = new Date(trackerPosition.time);
					if (isValidDate(newDate) === true) {
						//trackerPosition.formattedTime = newDate.toLocaleString('fr', {timeZone: 'Europe/Paris', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'});
						trackerPosition.formattedTime = dateTimeDiff(newDate);
						this.liveTimePosition[trackerPosition.id] = trackerPosition;
					}
				}
			},
			centerOnMarker(id) {
				this.$live.centeredMarkerId = id;
			},
		},
	};
};
