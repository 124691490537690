import Config from './config';

const URL = Config.serverTracker;

export default {
	async getCompetitions() {
		const request = await window.fetch(`${URL}/api/competitions`);
		return await request.json();
	},
	async getCompetition(id) {
		const request = await window.fetch(`${URL}/api/competitions/${id}`);
		return await request.json();
	},
	async getStage(id) {
		const request = await window.fetch(`${URL}/api/stages/${id}`);
		return await request.json();
	},
	async getTracker(id) {
		const request = await window.fetch(`${URL}/api/positions/${id}`);
		return await request.json();
	},
	async getTrackers(ids) {
		const request = await window.fetch(`${URL}/api/group/${encodeURI(JSON.stringify(ids))}`);
		return await request.json();
	},
};
